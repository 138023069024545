import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { getDetectedResults } from "../services/detected-values";
import { useEffectAsync } from "../utils";
import { Loading } from "../components/loader";
import { PreivewImageContext } from "../App";

export const DetectedResultsPage = () => {
    const [detectedResults, setDetectedResults] = useState<[string, unknown][]>([])
    const [loading, setLoading] = useState(true)
    const [previewContext] = useContext(PreivewImageContext)
    const deviceID = localStorage.getItem('deviceID') ?? ''
    const navigate = useNavigate();
    useEffectAsync(async () => {
        const results = await getDetectedResults(deviceID)
        setLoading(false)
        if (results) {
            const entries = Object.entries(results.taskJson)
            if (entries.length > 0) {
                setDetectedResults(entries)
            }
        }
    }, [])

    return (loading ? <Loading msg={"Calculating"} /> :
        < div className="detected-results-wrapper">
            <h2>Scanned results</h2>
            {
                detectedResults && detectedResults.length > 0 ?
                    <table className="table-wrapper">
                        <thead>
                            <tr>
                                <th className="table-column">Day</th>
                                <th className="table-column">Activity</th>
                                <th className="table-column">Headache</th>
                                <th className="table-column">Sleep</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detectedResults.map((day: any, index: number) => {
                                return <tr key={index}>
                                    <td className="table-column">Day: {day[0]}</td>
                                    <td className="table-column">{day[1].val1.map((value: any, index: number) => <span key={`Val1-${index}`}>{`${value} `}</span>)}</td>
                                    <td className="table-column">{day[1].val2.map((value: any, index: number) => <span key={`Val2-${index}`}>{`${value} `}</span>)}</td>
                                    <td className="table-column">{day[1].val3.map((value: any, index: number) => <span key={`Val3-${index}`}>{`${value} `}</span>)}</td>
                                </tr>
                            }
                            )}
                        </tbody>

                    </table>
                    : <h2>Requested data does not exist</h2>
            }
            <div className="buttons-wrapper">
                {
                    previewContext.file ?
                        // <Button className="button" variant="contained" onClick={handleGetPicture}>
                        //     Download Picture
                        // </Button> : null
                        <a download="my-head-health.png" href={`${previewContext.file}`}>Download Picture</a> : null
                }
                <Button className="button" variant="contained" onClick={() => navigate("/", { replace: true })}>
                    Back to home page
                </Button>
            </div>
        </div >
    )
}