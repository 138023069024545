import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { PreivewImageContext } from "../App";
import { postPicture } from "../services/picture";
import { createBlob } from "../services/utils";
import { Loading } from "../components/loader";

export function PreviewPicture() {
    const navigate = useNavigate();
    const deviceID = localStorage.getItem("deviceID") ?? ''
    // eslint-disable-next-line
    const [previewContext, setSetPreviwContext] = useContext(PreivewImageContext)
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const canvasOutput = document.querySelector('#drawRectangle') as any
        let image = new Image()
        image.src = previewContext.file
        const ctx = canvasOutput!.getContext('2d')
        image.onload = function () {
            ctx.drawImage(image, 0, 0)
            const plotPoints = () => {
                const points = previewContext.points
                console.log(points)
                if (points.length > 0) {
                    ctx.strokeStyle = 'yellow'
                    ctx.lineWidth = 5
                    ctx.beginPath()
                    ctx.moveTo(points[0].x, points[0].y)
                    ctx.arc(points[0].x, points[0].y, 5, 0, 5 * Math.PI)
                    Object.values(points).forEach((ps: any) => {
                        ctx.lineTo(ps.x, ps.y)
                        ctx.arc(ps.x, ps.y, 5, 0, 5 * Math.PI)
                    })
                    ctx.closePath()
                    ctx.stroke()
                }
            }
            plotPoints()
        }
    }, [previewContext])
    const handleResultsNavigation = async () => {
        try {
            setIsLoading(true);
            const keys = ['topLeft', 'bottomLeft', 'bottomRight', 'topRight']
            const newPoints = previewContext.points.reduce((acc, key, index, array) => {
                acc.renamedPoints[keys[index]] = previewContext.points[index]
                return acc
            }, { renamedPoints: { topLeft: { x: 0, y: 0 }, bottomLeft: { x: 0, y: 0 }, bottomRight: { x: 0, y: 0 }, topRight: { x: 0, y: 0 } } } as any)
            const binaryImage = createBlob(previewContext.file)
            await postPicture(deviceID, binaryImage, newPoints.renamedPoints)
            const canvasOutput = document.querySelector('#drawRectangle') as any
            const ctx = canvasOutput!.getContext('2d')
            await ctx.clearRect(0, 0, canvasOutput.width, canvasOutput.height)
            navigate("/results")
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false);
        }

    }
    return (
        <div style={{ width: "-webkit-fill-available" }}>
            {isLoading && <Loading msg="Uploading image"></Loading>}
            <div className="previewPage" style={{ height: "-webkit-fill-available", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <canvas id="drawRectangle" style={{ width:'100%',height:"100%" }} width={previewContext.canvasWidth} height={previewContext.canvasHeight} />

                <div style={{ display: "flex", position: "absolute", bottom: "20px", flexDirection: "row", width:"100%", justifyContent:"space-evenly" }}>
                    <Button className="button" variant="contained" onClick={() => navigate("/camera")} style={{ margin: "0", padding:"6px 16px" }}>
                        Back to screenshot
                    </Button>

                    <Button className="button" variant="contained" onClick={handleResultsNavigation} style={{ margin: "0",padding:"6px 16px" }}>
                        See results
                    </Button>

                </div>
            </div>
        </div>
    );
}