import React, { useRef, useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useOpenCv } from 'opencv-react'
import { PreivewImageContext } from '../App';
import { getMobileOperatingSystem } from '../utils';
import type cv from '@anpanman/opencv_ts';
import { Loading } from '../components/loader';

const currentWindowWidth = window.innerWidth
const currentWindowHeight = window.innerHeight
const operatingSystem = getMobileOperatingSystem()

declare module '@anpanman/opencv_ts' {
    export class VideoCapture {
        constructor(source: string | HTMLVideoElement);
        read(frame: cv.Mat): void;
    }
    export function imshow(taregt: string | HTMLCanvasElement, mat: cv.Mat): void;
}
declare global {
    interface MediaTrackSettings {
        zoom: number;
    }
    interface MediaTrackCapabilities {
        torch: boolean;
        zoom?: {
            min: number
        }
    }
    interface MediaTrackConstraintSet {
        torch?: boolean;
        zoom?: number;
    }
}

const MEDIA_DEVICE_CONSTRAINTS = {
    audio: false,
    video: {
        width: { min: 640, ideal: operatingSystem === 'iOS' ? currentWindowWidth : 1200, max: 1920 },
        height: { min: 400, ideal: operatingSystem === 'iOS' ? currentWindowHeight : 720, max: 1080 },
        facingMode: "environment",
    },

};

export function CameraScanner() {
    const [, setSetPreviwContext] = useContext(PreivewImageContext)

    const { loaded, cv } = useOpenCv();
    const navigate = useNavigate();
    let picturePoints: cv.Point[] = []
    const FPS = 30
    let takeScreenShot = false;
    const video_tag_html_element = useRef<HTMLVideoElement>(null);

    function createRectangle(src: cv.Mat) {

        const plotPoints = (canvas: HTMLCanvasElement, points: cv.Point[]) => {
            const ctx = canvas.getContext('2d')
            if(!ctx) return;
            ctx.strokeStyle = 'yellow'
            ctx.lineWidth = 5

            ctx.beginPath()
            ctx.moveTo(points[0].x, points[0].y)
            ctx.arc(points[0].x, points[0].y, 5, 0, 5 * Math.PI)
            Object.values(points).forEach(ps => {
                ctx.lineTo(ps.x, ps.y)
                ctx.arc(ps.x, ps.y, 5, 0, 5 * Math.PI)
            })
            ctx.closePath()
            ctx.stroke()
        }

        function contourToPoints(contour: cv.Mat | undefined) {
            const points: cv.Point[] = []
            if (contour && contour.data32S) {

                for (let j = 0; j < contour.data32S.length; j += 2) {
                    let p = {
                        x: contour.data32S[j],
                        y: contour.data32S[j + 1],
                    }
                    points.push(p)
                }
            }
            return points;
        }
        const getMaxContour = (image: cv.Mat) => {

            let contours = new cv.MatVector();
            let hierarchy = new cv.Mat();
            cv.findContours(image, contours, hierarchy, cv.RETR_TREE, cv.CHAIN_APPROX_SIMPLE);

            let maxAreaFound = 0;
            let maxContour: cv.Mat | undefined;
            let points: cv.Point[][] = [];
            //let contourObj = null;
            for (let i = 0; i < contours.size(); i++) {
                const contour = contours.get(i);
                const perimeter = cv.arcLength(contour, true);
                const approx = new cv.Mat();
                cv.approxPolyDP(contour, approx, 0.02 * perimeter, true);
                const area = cv.moments(contour)['m00'];
                if (approx.rows === 4 && maxAreaFound < area) {
                    maxAreaFound = area;
                    maxContour = approx;
                    //contourObj = contour;
                }
                points.push(contourToPoints(approx));
            };
            return {
                contour: maxContour,
                area: maxAreaFound
            };
        }


        var dest = new cv.Mat();
        cv.imshow('drawRectangle', src);

        cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 4);
        let ksize = new cv.Size(5, 5);
        cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);
        cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);
        cv.GaussianBlur(src, src, ksize, 0, 0, cv.BORDER_DEFAULT);
        // cv.threshold(src, src, 120, 255, cv.THRESH_BINARY);

        cv.Canny(src, dest, 80, 100, 3, false); // You can try more different parameters
        // display the output to canvas

        const canvasOutput = document.querySelector('#drawRectangle') as HTMLCanvasElement
        let { area, contour } = getMaxContour(dest);

        let dilateIterations = 0;

        const MAX_DILATE_ITERATIONS = 1;
        while (dilateIterations < MAX_DILATE_ITERATIONS) {
            dilateIterations++;
            let M = cv.Mat.ones(5, 5, cv.CV_8U);
            // let M  = cv.getStructuringElement(cv.MORPH_RECT,{ width: 1, height: 1 });
            let anchor = new cv.Point(-1, -1);
            cv.dilate(dest, dest, M, anchor, 1, cv.BORDER_CONSTANT);

            let result = getMaxContour(dest);
            contour = result.contour;
            // eslint-disable-next-line
            area = result.area;

            if (contour && contour.data32S) {
                picturePoints = contourToPoints(contour);
                plotPoints(canvasOutput, picturePoints)
            }

        }

        // remember to free the memory
        src.delete();
        dest.delete();

    }
    const [isCapturing, setIsCapturing] = useState(false);
    function startCapture() {
        if (!cv) return;
        if (video_tag_html_element.current) {
            video_tag_html_element.current.height = video_tag_html_element.current.videoHeight;
            video_tag_html_element.current.width = video_tag_html_element.current.videoWidth;
            let frame = new cv.Mat(video_tag_html_element.current.videoHeight, video_tag_html_element.current.videoWidth, cv.CV_8UC4);
            let videoCapture = new cv.VideoCapture(video_tag_html_element.current);
            setTrack(true);
            function processFrame() {
                try {
                    let begin = Date.now();
                    videoCapture.read(frame)

                    let frame_clean = frame.clone();

                    if (takeScreenShot) {
                        // eslint-disable-next-line
                        handleTakeScreenShot(frame_clean, picturePoints)
                        // eslint-disable-next-line
                        takeScreenShot = false
                        return
                    }
                    createRectangle(frame_clean)

                    let delay = 1000 / FPS - (Date.now() - begin);
                    timeoutId = setTimeout(processFrame, delay);
                } catch (err) {
                    console.log(err);
                }
            };

            let timeoutId = setTimeout(processFrame, 0);
            return () => clearTimeout(timeoutId);
        }
    };
    useEffect(() => {
        if(!isCapturing) return;
        let isDisposed = false;
        let cleanup: (() => void ) | undefined;
        async function initVideoCapture() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(MEDIA_DEVICE_CONSTRAINTS);
                if (stream) {
                    if (video_tag_html_element.current) {
                        video_tag_html_element.current.srcObject = stream;
                        video_tag_html_element.current.setAttribute('autoplay', '');
                        video_tag_html_element.current.setAttribute('muted', '');
                        video_tag_html_element.current.setAttribute('playsinline', '');
                    }
                }
                if(isDisposed) return;
                if (video_tag_html_element.current) {
                    function onLoadStreamData() {
                        if(isDisposed) return;
                        removeListener();
                        cleanup = startCapture();
                    }
                    function removeListener() {
                        video_tag_html_element.current?.removeEventListener("loadedmetadata", onLoadStreamData, false);
                    }
                    video_tag_html_element.current.addEventListener("loadedmetadata", onLoadStreamData, false);
                    cleanup = removeListener;
                }
                
            } catch (e) {
                console.log("An error occurred! " + e);
            }
        }
        initVideoCapture();
        const camera = video_tag_html_element.current;
        return () => {
            stopCamera(camera);
            cleanup?.();
        }
        // eslint-disable-next-line
    }, [video_tag_html_element.current, cv, isCapturing]);

    useEffect(() => {
        const fn = () => {
            if (document.visibilityState === "visible") {
                setIsCapturing(true);
            } else {
                setIsCapturing(false);
            }
        };
        setIsCapturing(true);
        document.addEventListener("visibilitychange", fn, true);
        return () => {
            document.removeEventListener("visibilitychange", fn);
        }
    }, []);

    function stopCamera(camera: HTMLVideoElement | null) {
        if (camera) {
            const stream = camera.srcObject;
            if(stream && 'getTracks' in stream) {
                const tracks = stream.getTracks();
                tracks.map(track => track.stop())
            }
        }
    }

    function setTrack(torch = false) {
        let tracks: MediaStreamTrack[] | undefined;
        if (video_tag_html_element.current) {
            const stream = video_tag_html_element.current.srcObject;
            if(stream && 'getTracks' in stream) {
                tracks = stream.getTracks();
            }
        }
        if (tracks) {
            try {
                let capabilities = tracks[0].getCapabilities()
                let settings = tracks[0].getSettings()
                let constraints = []
                if (capabilities.torch) {
                    constraints.push({ torch: torch })
                }
                if ('zoom' in settings) {
                    constraints.push({ zoom: capabilities.zoom ? capabilities.zoom.min : settings.zoom })
                }

                if (constraints.length) {
                    tracks[0].applyConstraints({
                        advanced: constraints
                    });
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleTakeScreenShot = async (frame: cv.Mat, points: cv.Point[]) => {
        setTrack(false)

        //could delete this using already existing canvas maybe?
        let tempCanvas = document.getElementById("drawRectangle") as HTMLCanvasElement;
        const newCanvasWidth = tempCanvas.getAttribute("width")
        const newCanvasHeight = tempCanvas.getAttribute("height")
        const cleanCanvas = document.createElement("canvas"); 
        cv.imshow(cleanCanvas, frame)
        let b64image = cleanCanvas.toDataURL()
        setSetPreviwContext({
            file: b64image,
            points: points,
            canvasHeight: +(newCanvasHeight ??  "0"),
            canvasWidth: +(newCanvasWidth ?? "0")
        });
        navigate("/preview")
    }


    return <div style={{ width: "-webkit-fill-available" }}>
        {!loaded && <Loading msg="Loading image processing library"></Loading>}
        <div className="vid_container" style={{ height: "-webkit-fill-available" }}>
            <div className="inputoutput" style={{ height: "inherit", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <video ref={video_tag_html_element} id="videoInput" hidden autoPlay={true} ></video>
                <canvas id="drawRectangle" style={{ /*transform: "scale(50%) translate(-15% -15%)" ,*/ width:'100%',height:"100%" }} />
                <div style={{ bottom: "20px", position: "absolute" }}>
                    <Button className="button" variant="contained" onClick={() => takeScreenShot = true} style={{ margin: "0px" }}>
                        Take screenshot
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

