import React from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import analyticsIcon from '../assets/analytics-icon.svg';

export const HomePage = () => {
  const navigate = useNavigate();
  const handleTakeTest = () => {
    navigate('/camera');
  };

  return (
    <div className='homepage'>
      <div className='image-container'>
        <img src={analyticsIcon} alt='logo' />
      </div>
      <div className='text-container'>
        <Typography variant='h4' className='title'>
          MyHeadHealth Analytics
        </Typography>
        <Typography variant='h6'>Scan your tracker. See your stats</Typography>
      </div>
      <Button
        className='button'
        variant='contained'
        onClick={handleTakeTest}
        style={{ padding: '10px 50px' }}
      >
        SCAN TRACKER
      </Button>
    </div>
  );
};
