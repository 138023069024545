export type ServerError = {
    url: RequestInfo;
    body: RequestInit["body"];
    status: Response;
    statusText: string;
    message: string;
    json: object;
    toLog(): {
        url: string;
        body: RequestInit["body"];
        json: any;
        status: number;
        statusText: string;
    };
    toString(): string;
};

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const response = await fetch(input, init);
    if (response.status !== 200) {
        const json = await response.json();
        // eslint-disable-next-line
        throw {
            url: input,
            body: init?.body,
            status: response.status,
            statusText: response.statusText,
            message: json.message,
            json,
            toLog() {
                return {
                    url: this.url,
                    body: this.body,
                    json: this.json,
                    status: this.status,
                    statusText: this.statusText,
                };
            },
            toString() {
                return `${this.status} - ${this.json.message || this.statusText}`;
            },
        };
    }
    const data = await response.json();
    return data as T;
}

export function addQueryParameter(
    url: URL,
    name: string,
    value: null | undefined | string | number | boolean | number[] | string[] | boolean[]
) {
    if (value == null) return;
    if (Array.isArray(value)) {
        for (let o of value) {
            url.searchParams.append(name, o?.toString());
        }
    } else {
        url.searchParams.append(name, value.toString());
    }
}
export function appendFormData(data: FormData, name: string, file: File) {
    data.append(name, file);
}

export function errorIsFromFetch(err: any): err is ServerError {
    return "url" in err && "body" in err && "status" in err;
}

export function createBlob(dataURL: string) {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        let parts = dataURL.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = decodeURIComponent(parts[1]);
        return new Blob([raw], {
            type: contentType
        });
    }
    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {
        type: contentType
    });
}