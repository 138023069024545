import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { CameraScanner } from './pages/camera-page';
import { HomePage } from './pages/home-page';
import { ResultsPage } from './pages/results-page';
import { NoMatch } from './pages/no-match';
import { PreviewPicture } from './pages/preview-page';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { OpenCvProvider } from 'opencv-react'
import { DetectedResultsPage } from './pages/detected-results-page';

type PreivewContext = {
  file: string
  points: { x: number, y: number }[],
  canvasHeight: number
  canvasWidth: number
}

export const PreivewImageContext = React.createContext<[PreivewContext, React.Dispatch<PreivewContext>]>([null!, null!]);

function App() {
  const [previewContext, setSetPreviwContext] = useState<PreivewContext>({ file: '', points: [{ x: 0, y: 0 }], canvasHeight: 0, canvasWidth: 0 })
  const [uuid,] = useState<string>(() => localStorage.getItem('deviceID') ?? uuidv4())
  
  useEffect(() => {
    const newUUID = uuidv4()
    localStorage.setItem('deviceID', newUUID)
  }, [uuid])
  return (<OpenCvProvider openCvPath={`${process.env.PUBLIC_URL}/opencv.js`}>
    <div className='App'>
      <PreivewImageContext.Provider value={[previewContext, setSetPreviwContext]}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/camera" element={<CameraScanner />} />
          <Route path="/preview" element={<PreviewPicture />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/detected-results" element={<DetectedResultsPage />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </PreivewImageContext.Provider>
    </div>
  </OpenCvProvider>
  );
}

export default App;
