import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
export function getDetectedResults(deviceID: string): Promise<any> {
    const url = new URL(`/detected-results/${deviceID}`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "GET", headers });
}

export function getDetectedPicture(deviceID: string): Promise<any> {
    const url = new URL(`/detected-picture/${deviceID}`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "GET", headers });
}
