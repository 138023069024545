import { CircularProgress } from "@mui/material";

export const Loading = ({ msg }: { msg: string }) => {
    return (
        <div className="wait-container">
            <CircularProgress size={100} color="secondary" />
            <br></br>
            <div>{msg}</div>
        </div>
    );
};