import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import gradientBar from "../assets/gradient_bar.png";
import arrow from "../assets/up_arrow.png";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStatusDeviceid } from "../services/task.status";
import { useEffectAsync } from "../utils";
import { errorIsFromFetch, ServerError } from "../services/utils";
import { Results } from "../models/results";
import { GradientCircularProgress } from "../components/gradientCircle";
import { Loading } from "../components/loader";
import { CategorySeparator } from "../components/CategorySeparator";

export const ResultsPage = () => {
    const navigate = useNavigate();
    const [results, setResults] = useState<Results | undefined>();
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [id, setId] = useState(0);
    const [error, setError] = useState<ServerError | undefined>(undefined);
    const [AIError, setAIError] = useState<string>();
    const interval = useRef<NodeJS.Timer>(null!);
    const deviceID = localStorage.getItem('deviceID') ?? ''
    useEffectAsync(async () => {
        interval.current = setInterval(async () => {
            try {
                const response = await getStatusDeviceid(deviceID);
                if (response?.code === 3 && response.results) {
                    setResults(response.results);
                    setLoading(false);
                    setId(response.id);
                }
                if (response?.code === 0) {
                    setAIError(response.message);
                    setLoading(false);
                    setResults("f" as any);
                }
            } catch (error) {
                if (errorIsFromFetch(error)) {
                    setError(error);
                }
                setResults("f" as any);
                setLoading(false);
            }
        }, 2000);
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (interval.current && !loading) {
            clearInterval(interval.current);
        }
    }, [loading]);

    if (!results) return <Loading msg={"Calculating"} />;
    else
        return error ? (
            <div className="error" style={{ display: "flex", flexDirection: "column" }}>
                <p>Error fetching results</p>
                <Button className="button" variant="contained" onClick={() => navigate("/", { replace: true })}>
                    Try Again
                </Button>
            </div>
        ) : (
            <div className="resultsPage">
                <div className="results">
                    <SleepResults AIError={AIError} results={results} />
                    <ActivityResults AIError={AIError} results={results} />
                </div>
                <div>
                    <Button className="button" variant="contained" onClick={() => navigate("/detected-results", { replace: true })}>
                        Check detection
                    </Button>
                    <Button className="button" variant="contained" onClick={() => navigate("/", { replace: true })}>
                        Try Again
                    </Button>
                </div>
            </div>
        );
};

const ActivityResults = ({ results, AIError }: { results: Results | undefined; AIError: string | undefined }) => {
    const imageMargin = { marginLeft: 12.5, marginRight: 12.5 }
    const pad = (value: number | undefined) => ({ marginLeft: typeof value === "number" ? (value - 1) * 150 : 0 });
    const status = (value: number | undefined) =>
        typeof value !== "number" ? "" :
            value <= 1.5 ? "Poor" :
                value <= 2.4 ? "Fair" :
                    "Good";
    const goodStatus = status(results?.daysStatusGood);
    const badStatus = status(results?.daysStatusBad);
    return (
        <Paper className="results-paper" elevation={2}>
            <Typography variant="h6">Inactive days</Typography>
            <Typography>On days you were <b>inactive</b>, your sleep was: </Typography>
            {AIError ? (
                <Typography>{AIError}</Typography>
            ) :
                (<div className="results-image-container">
                    <img className="gradient-bar" src={gradientBar} alt="first gradient bar" style={imageMargin} />
                    <img style={pad(results?.daysStatusBad)} className="arrow" src={arrow} alt="first gradient bar arrow" />
                </div>)}
            <p className={badStatus.toLowerCase()}>{badStatus}</p>
            <CategorySeparator />
            <Typography variant="h6">Active days</Typography>
            <Typography>On days you were <b>active</b>, your sleep was: </Typography>

            {AIError ? (
                <Typography>{AIError}</Typography>
            ) :
                (<div className="results-image-container">
                    <img className="gradient-bar" src={gradientBar} alt="second gradient bar" style={imageMargin} />
                    <img
                        style={pad(results?.daysStatusGood)}
                        className="arrow"
                        src={arrow}
                        alt="second gradient bar arrow"
                    />
                </div>)}
            <p className={goodStatus.toLowerCase()}>{goodStatus}</p>
        </Paper>
    );
};
function round(n: number) {
    return Math.round(n * 10) / 10;
}
const SleepResults = ({ results, AIError }: { results: Results | undefined; AIError: string | undefined }) => {
    return (
        <Paper className="results-paper" elevation={2}>
            <Typography variant="h6">Poor Sleep & Headaches</Typography>
            <Typography>When you <b>slept poorly</b>, your headache score the following day was:</Typography>
            <div className="circle-container">
                {AIError ? (
                    <Typography>{AIError}</Typography>
                ) : (
                    <GradientCircularProgress
                        progress={Math.round(results!.sleepStatusBad * 10)}
                        progressDisplay={round(results!.sleepStatusBad).toFixed(1)}
                        size={130}
                        strokeWidth={12}
                        startColor="#3ed43b"
                        middleColor="yellow"
                        endColor="red"
                        emptyColor="lightGray"
                    />
                )}
            </div>
            <CategorySeparator />
            <Typography variant="h6">Good Sleep & Headaches</Typography>
            <Typography>When you <b>slept well</b>, your headache score the following day was:</Typography>
            <div className="circle-container">
                {AIError ? (
                    <Typography>{AIError}</Typography>
                ) : (
                    <GradientCircularProgress
                        progress={Math.round(results!.sleepStatusGood * 10)}
                        progressDisplay={round(results!.sleepStatusGood).toFixed(1)}
                        size={130}
                        strokeWidth={12}
                        startColor="#3ed43b"
                        middleColor="yellow"
                        endColor="red"
                        emptyColor="lightGray"
                    />
                )}
            </div>
        </Paper>
    );
};

