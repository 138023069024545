import { PicturePoints } from "../types/picture-points-type";
import { baseUrl, headers } from "./config";
import {  fetchAndParse } from "./utils";

export function postPicture(deviceID: string, file: Blob, points: PicturePoints): Promise<string> {
    const localHeaders = { ...headers };
    delete localHeaders["Content-Type"];
    const data = new FormData();
    data.append("deviceID", deviceID)
    data.append("file", file)
    data.append("fileProperties", JSON.stringify(points))
    const url = new URL(`/picture`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "POST", body: data, headers: localHeaders });
}
